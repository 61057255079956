<template>
  <footer class="home-footer mt-5">
    <r-container>
      <r-row>
        <r-col class="col-12">
          <div class="d-flex color-card br-lg pa-3 v-center h-space-between">
            <div class="community-text">
              <h3 class="title-1 font-weight-bold">
                {{ $t("join_community") }}
              </h3>
              <div>
                {{ $t("join_community_desc") }}
              </div>
            </div>
            <r-btn
              class="ms-2 color-black color-white-text br-md"
              :to="{ name: 'dashboard' }"
              >{{ $t("join") }}</r-btn
            >
          </div>
        </r-col>
        <r-col class="col-6 md-3">
          <div class="title-2 mb-3">{{ $t("product") }}</div>
          <div class="title-3 mb-2">{{ $t("ecommerce") }}</div>
          <div class="title-3 mb-2">{{ $t("doctor") }}</div>
          <div class="title-3 mb-2">{{ $t("company") }}</div>
          <div class="title-3 mb-2">{{ $t("blog") }}</div>
        </r-col>
        <r-col class="col-6 md-3">
          <div class="title-2 mb-3">
            {{ $t("help") }}
          </div>
          <div class="title-3 mb-2">{{ "domain_help" }}</div>
          <div class="title-3 mb-2">{{ "bot_help" }}</div>
          <div class="title-3 mb-2">{{ "zarinpal_account" }}</div>
        </r-col>
        <r-col class="col-6 md-3">
          <div class="title-2 mb-3">{{ $t("pages") }}</div>
        </r-col>
        <r-col class="col-6 md-3">
          <r-img
            src="/pwa/logo?t=m&w=180"
            width="180"
            height="60"
            alt="codenus"
          ></r-img>
          <div>
            <r-btn
              v-for="(item, i) in social_media"
              :key="i"
              icon
              outlined
              class="ma-2 br-lg btn-icon"
              v-title="i"
              :href="item"
              target="_blank"
            >
              <r-img
                :src="`/strg/logos/${i}.svg`"
                :alt="i"
                width="25"
                height="25"
                is-svg
              />
            </r-btn>
          </div>
          <install-app class="mt-5"></install-app>
        </r-col>
      </r-row>
    </r-container>

    <r-container full-width class="color-black text-center title-3">
      <r-row class="h-center no-gutters">
        <r-col class="col-auto color-white-text">
          {{ $t("footer_copyright_msg") }}
        </r-col>
      </r-row>
    </r-container>
  </footer>
</template>

<script>
import InstallApp from "./installApp";
export default {
  name: "homeFooter",
  components: { InstallApp },
  data() {
    return {
      social_media: {},
    };
  },
  created() {
    this.get();
  },
  methods: {
    go(hash) {
      if (this.$route.name !== hash.to.name) {
        this.$router.push(hash.to);
        setTimeout(() => {
          const el = document.getElementById(hash.id);
          if (el !== null) {
            el.scrollIntoView();
          }
        }, 1000);
      } else {
        setTimeout(() => {
          const el = document.getElementById(hash.id);
          if (el !== null) {
            el.scrollIntoView();
          }
        }, 100);
      }
    },
    get() {
      this.$axios.get("home/settings/multi/social_media").then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          this[data[i]["name"]] = data[i]["value"];
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";

.home-footer {
  .btm-line {
    width: 150px;
    height: 4px;
  }
  .btn-icon {
    opacity: 0.5;
    &:hover {
      background-color: #000;
      color: white;
      opacity: 1;
    }
  }
}
</style>
